import { createRouter, createWebHistory } from 'vue-router'

import store from '@/store';
import { TokenService } from '@/services';

import MainLayout from '../layout/MainLayout.vue';

import Login from '../pages/Auth/Login';
import Recover from '../pages/Auth/Recover';
import ActiveAccount from '../pages/Auth/ActiveAccount';
import CreatePassword from '../pages/Auth/CreatePassword';
import ResetPassword from '../pages/Auth/ResetPassword';
import Welcome from '../pages/User/Welcome';
import Dashboard from '../pages/Dashboard';
import Briefings from '../pages/Briefings/Briefings';
import Briefing from '../pages/Briefings/Briefing';
import NetworkDirectory from '../pages/NetworkDirectory';
import Slack from '../pages/Slack';
import Contact from '../pages/Contact';
import CalendarPage from '../pages/CalendarPage';

import Admin from '../pages/Admin/Admin';
import DocumentsList from '../pages/Admin/Documents/List';
import DocumentsCreate from '../pages/Admin/Documents/Create';
import UsersList from '../pages/Admin/Users/List';
import UsersCreate from '../pages/Admin/Users/Create';
import EventsList from '../pages/Admin/Events/List';
import EventsCreate from '../pages/Admin/Events/Create';
import TopicsList from '../pages/Admin/Topics/List';
import TopicsCreate from '../pages/Admin/Topics/Create';

const routes = [{
    path: '/user',
    name: 'Authentication',
    component: MainLayout,
    redirect: '/user/login',
    children:
    [
        {
            path: 'login',
            name: 'Login',
            component: Login,
            meta:
            {
                public: true,
                only_when_logged_out: true
            },
        },
        {
            path: 'recover',
            name: 'Recover',
            component: Recover,
            meta:
            {
                public: true,
                only_when_logged_out: true
            },
        },
        {
            path: 'reset-password/:token/:email',
            name: 'ResetPassword',
            component: ResetPassword,
            meta:
            {
                public: true,
                only_when_logged_out: true
            },
        },
        {
            path: 'activate',
            name: 'ActiveAccount',
            component: ActiveAccount,
            meta:
            {
                public: true,
                only_when_logged_out: true
            },
        },
        {
            path: 'activate/:token/:email',
            name: 'CreatePassword',
            component: CreatePassword,
            meta:
            {
                public: true,
                only_when_logged_out: true
            },
        },
        {
            path: 'welcome',
            name: 'Welcome',
            component: Welcome,
            meta:
            {
                no_header: true,
            },
        },
        {
            path: 'logout',
            name: 'Logout',
            component:
            {
                beforeRouteEnter()
                {
                    store.dispatch('Auth/logout');
                },
            },
        },
    ]
},
{
    path: '/',
    name: 'Dashboard',
    component: MainLayout,
    children:
    [
        {
            path: '/',
            name: 'Dashboard',
            component: Dashboard,
            children:
            [
                {
                    path: 'admin',
                    name: 'Admin',
                    component: Admin,
                    redirect: '/admin/documents',
                    meta:
                    {
                        authorize: ['super-admin', 'admin']
                    },
                    children:
                    [
                        {
                            path: 'documents',
                            name: 'DocumentsList',
                            component: DocumentsList,
                            meta:
                            {
                                title: 'Content Management',
                                subtitle: 'ADMINISTRATION PAGE FOR MANAGING AND CREATING CONTENT',
                            },
                        },
                        {
                            path: 'documents/create',
                            name: 'DocumentsCreate',
                            component: DocumentsCreate,
                            meta:
                            {
                                title: 'Create New Document',
                                subtitle: 'ADMINISTRATION PAGE FOR CREATING CONTENT',
                            },
                        },
                        {
                            path: 'documents/:id/edit',
                            name: 'DocumentsEdit',
                            component: DocumentsCreate,
                            meta:
                            {
                                title: 'Edit Document',
                                subtitle: 'ADMINISTRATION PAGE FOR CREATING CONTENT',
                            },
                        },

                        {
                            path: 'users',
                            name: 'UsersList',
                            component: UsersList,
                            meta:
                            {
                                title: 'Users Management',
                                subtitle: 'ADMINISTRATION PAGE FOR MANAGING AND CREATING USERS',
                            },
                        },
                        {
                            path: 'users/create',
                            name: 'UsersCreate',
                            component: UsersCreate,
                            meta:
                            {
                                title: 'Users Management',
                                subtitle: 'ADMINISTRATION PAGE FOR MANAGING AND CREATING USERS',
                                authorize: ['super-admin'],
                            },
                        },
                        {
                            path: 'users/:id/edit',
                            name: 'UsersEdit',
                            component: UsersCreate,
                            meta:
                            {
                                title: 'Users Management',
                                subtitle: 'ADMINISTRATION PAGE FOR MANAGING AND CREATING USERS',
                                authorize: ['super-admin'],
                            },
                        },

                        {
                            path: 'events',
                            name: 'EventsList',
                            component: EventsList,
                            meta:
                            {
                                title: 'Timeline Management',
                                subtitle: 'ADMINISTRATION PAGE FOR MANAGING AND CREATING TIMELINE EVENTS',
                            },
                        },
                        {
                            path: 'events/create',
                            name: 'EventsCreate',
                            component: EventsCreate,
                            meta:
                            {
                                title: 'Create New Timeline Event',
                                subtitle: 'ADMINISTRATION PAGE FOR CREATING TIMELINE EVENTS',
                            },
                        },
                        {
                            path: 'events/:id/edit',
                            name: 'EventsEdit',
                            component: EventsCreate,
                            meta:
                            {
                                title: 'Edit Timeline Event',
                                subtitle: 'ADMINISTRATION PAGE FOR CREATING TIMELINE EVENTS',
                            },
                        },
                        {
                            path: 'topics',
                            name: 'TopicsList',
                            component: TopicsList,
                            meta: {
                                title: 'Topics/Tags Management',
                                subtitle: 'ADMINISTRATION PAGE FOR MANAGING AND CREATING TOPICS/TAGS',
                            }
                        },
                        {
                            path: 'topics/create',
                            name: 'TopicsCreate',
                            component: TopicsCreate,
                            meta:
                            {
                                title: 'Create New Topic/Tag',
                                subtitle: 'ADMINISTRATION PAGE FOR CREATING TOPICS/TAGS',
                            },
                        },
                        {
                            path: 'topics/:id/edit',
                            name: 'TopicsEdit',
                            component: TopicsCreate,
                            meta:
                            {
                                title: 'Edit Topic/Tag',
                                subtitle: 'ADMINISTRATION PAGE FOR EDITING TOPICS/TAGS',
                            },
                        },
                    ],
                },
            ],
        },
        {
            path: 'briefings',
            name: 'Briefings',
            component: Briefings
        },
        {
            path: 'briefing/:id',
            name: 'Briefing View',
            component: Briefing
        },
        {
            path: 'network-directory',
            name: 'Network directory',
            component: NetworkDirectory,
            meta:
            {
                authorize: ['super-admin', 'admin', 'global-team']
            },
        },
        {
            path: 'slack-channel',
            name: 'Slack channel',
            component: Slack,
            meta:
            {
                authorize: ['super-admin', 'admin', 'global-team']
            },
        },
        {
            path: 'contact',
            name: 'Contact',
            component: Contact
        },
        {
            path: 'calendar',
            name: 'Calendar',
            component: CalendarPage,
            meta:
            {
                authorize: ['super-admin', 'admin', 'global-team']
            },
        },
        {
            path: 'privacy-policy',
            name: 'Privacy Policy',
            component: Dashboard,
        },
    ]
},
];

const router = createRouter(
{
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) =>
{
    const is_public = to.matched.some(record => record.meta.public);
    const only_when_logged_out = to.matched.some(record => record.meta.only_when_logged_out);
    const logged_in = TokenService.has_token();

    if (!is_public && !logged_in)
    {
        sessionStorage.setItem('redirect', to.path);

        return next({
            path: '/user/login',
        });
    }

    if ((!to.meta || to.meta.only_when_logged_out !== false) && logged_in && only_when_logged_out)
    {
        return next('/');
    }

    if (logged_in)
    {
        let initializations = [];

        initializations.push(store.dispatch('Auth/init'));

        Promise.all(initializations).then(function ()
        {
            const logged_user = store.getters['Auth/user'];
            const new_pass_path = '/user/new-passwod';
            const logout_path = '/user/logout';
            const welcome_path = '/user/welcome';

            if (logged_user.requre_new_password && [new_pass_path, logout_path, welcome_path].indexOf(to.path) === -1)
            {
                return next(new_pass_path);
            }

            if (to.meta.authorize && to.meta.authorize.indexOf(logged_user.role) === -1)
            {
                return next('/');
            }

            if ((logged_user.status === null || logged_user.status === 'NEW') && [new_pass_path, logout_path, welcome_path].indexOf(to.path) === -1)
            {
                return next(welcome_path);
            }

            next();
        });
    }
    else
    {
        next();
    }
});

export default router;
