<template>
    <header class="main-header"
        :class="{
            'icph-only-logo': onlyLogo,
            'icph-sticky': sticky
        }">
        <router-link to="/" class="icph-logo"></router-link>
        <button
            type="button"
            class="icph-open-menu"
            @click="toggle_menu"
            >
        </button>
        <div class="icph-profile" @click="show_profile_popup = true">
            <profile-picture :src="get_user_picture()"></profile-picture>
            <p>Welcome<b>{{ user.name }}</b></p>
        </div>
        <div
            class="icph-main-menu-holder"
            :class="{'icph-expanded': menu_expanded}"
            >
            <button class="icph-close-menu" @click="toggle_menu">X</button>
            <div class="icph-profile" @click="show_profile_popup = true">
                <profile-picture :src="get_user_picture()"></profile-picture>
                <p>Welcome<b>{{ user.name }}</b></p>
            </div>
            <div class="icph-main-menu">
                <div class="icph-searh-holder">
                    <base-input
                        icon-left="icon-search"
                        placeholder="Search"
                        v-model="search_term"
                        v-on:keyup.enter="perform_search"
                        :round="true"
                        >
                        <template v-slot:icon-right>
                        </template>
                    </base-input>
                    <Button class="icph-search-button" icon="icon-search" color="primary" @click="perform_search">
                        Search
                    </Button>
                </div>
                <nav class="icph-menu">
                    <router-link to="/" class="icph-menu-item icph-item-home" @click="this.menu_expanded = false"><span>Home</span></router-link>
                    <router-link to="/briefings" class="icph-menu-item icph-item-briefings" @click="this.menu_expanded = false"><span>Briefings</span></router-link>
                    <router-link to="/calendar" class="icph-menu-item icph-item-calendar" v-if="can_see" @click="this.menu_expanded = false"><span>Calendar</span></router-link>
                    <router-link to="/network-directory" class="icph-menu-item icph-item-network-directory"  v-if="can_see" @click="this.menu_expanded = false"><span>Network Directory</span></router-link>
                    <!-- <router-link to="/charts" class="icph-menu-item icph-item-charts" @click="this.menu_expanded = false"><span>Charts</span></router-link> -->
                    <router-link to="/slack-channel" class="icph-menu-item icph-item-slack-channel" v-if="can_see" @click="this.menu_expanded = false"><span>Slack Channel</span></router-link>
                    <!-- <a href="https://cloud.icphub.org" target="_blank" class="icph-menu-item icph-item-cloud"><span>ICPH Cloud</span></a> -->
                    <router-link to="/contact" class="icph-menu-item icph-item-contact-us" @click="this.menu_expanded = false"><span>Contact Us</span></router-link>
                </nav>
            </div>
        </div>
        <ProfilePopup v-if="!onlyLogo" v-model="show_profile_popup" />
        <!-- <div class="icph-notification">
            <p>The site is experiencing technical difficulties. We are working to fix them and will be operational in a couple of hours.</p>
        </div> -->
    </header>
</template>

<script>
    import ProfilePicture from '@/components/ProfilePicture';
    import BaseInput from '@/components/Inputs/BaseInput';
    import ProfilePopup from  '@/popups/Profile';
    import Button from '@/components/Buttons/Button';

    export default
    {
        name: 'Header',
        components:
        {
            ProfilePicture,
            BaseInput,
            ProfilePopup,
            Button,
        },
        props: {
            onlyLogo: {
                type: Boolean,
                default: false
            },
            sticky: {
                type: Boolean,
                default: false
            }
        },
        data()
        {
            return {
                menu_expanded: false,
                show_profile_popup: false,
                search_term: '',
            };
        },
        computed:
        {
            user()
            {
                return this.$store.getters['Auth/user'];
            },
            can_see()
            {
                const role = this.user.role;

                return (
                    role === 'super-admin' ||
                    role === 'admin' ||
                    role === 'global-team'
                );
            },
        },
        mounted()
        {
            // this.user = this.$store.getters['Auth/user'];

        },
        methods:
        {
            toggle_menu()
            {
                this.menu_expanded = !this.menu_expanded;
            },

            get_user_picture()
            {
                return (this.user.profile_picture_id ? process.env.VUE_APP_BACKEND_URL + 'api/files/' + this.user.profile_picture_id : 0)
            },

            perform_search()
            {
                this.$store.commit('BriefingSearch/setSearchTerm', this.search_term)
                this.$router.push({ name: 'Briefings' });
            },
        },
        watch: {
            '$store.state.BriefingSearch.searchTerm': function(newVal) {
                this.search_term = newVal;
            }
        }
    }
</script>
