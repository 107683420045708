<template>
    <button
        type="button"
        class="icph-button"
        :class="{
            [`icph-color-${color}`]: color,
            'icph-round': round,
            'icph-has-icon': icon || iconRight,
            'icph-disabled': disabled,
            'icph-label-button': isLink,
        }"
        >
        <slot name="loading">
            <i v-if="loading" class="icph-loading"></i>
        </slot>
        <slot name="icon">
            <i v-if="icon" class="icph-icon" :class="icon"></i>
        </slot>
        <slot></slot>
        <slot name="icon-right">
            <i v-if="iconRight" class="icph-icon" :class="iconRight"></i>
        </slot>
    </button>
</template>

<script>
    export default
    {
        name: 'Button',
        props:
        {
            round: Boolean,
            color: String,
            icon: String,
            iconRight: String,
            loading: Boolean,
            disabled: Boolean,
            isLink:
            {
                type: Boolean,
                description: 'Whether button is a link (no background and borders)'
            },
        },
        methods:
        {
        },
    }
</script>
