<template>
    <div
        class="icph-page-login"
        :class="{
            'icph-loading': loading
        }"
        >
        <panel>
            <div class="icph-activate-panel">
                <!-- <p>Welcome to the new ICPH website.</p> -->
                <p><b>You will need to activate your account</b> before you can log in. If you have not already done so please click activate.</p>
                <router-link to="/user/activate" class="icph-button">ACTIVATE</router-link>
            </div>
            <h1 class="icph-lineunder">Sign in</h1>
            <p>Welcome to the International Climate Politics Hub website. Please log in below.</p>
            <loader />
            <form @submit.prevent="login">
                <base-input
                    label="E-mail"
                    type="email"
                    placeholder=""
                    v-model="model.email"
                    >
                </base-input>
                <base-input
                    label="Password"
                    type="password"
                    :error="error"
                    v-model="model.password"
                    >
                </base-input>
                <Button type="submit">Login</Button>
                <router-link to="/user/recover" class="icph-label-button">Forgot password?</router-link>
                <p>If you're having any issues please contact <a href="mailto:info@icphub.org">info@icphub.org</a></p>
            </form>
        </panel>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    import Panel from '@/components/Panels/Panel';
    import BaseInput from '@/components/Inputs/BaseInput';
    import Button from '@/components/Buttons/Button';
    import Loader from '@/components/Loader';

    export default
    {
        name: 'Login',
        components:
        {
            Panel,
            BaseInput,
            Button,
            Loader,
        },
        data()
        {
            return {
                submitted: false,
                loading: false,
                model:
                {
                    email: '',
                    password: '',
                },
            };
        },
        computed:
        {
            ...mapGetters('Auth',
            [
                'authenticating',
                'error'
            ]),
        },
        methods:
        {
            async login()
            {
                this.loading = true;

                await this.$store.dispatch('Auth/login',
                {
                    email: this.model.email,
                    password: this.model.password,
                });

                this.loading = false;
                // this.$router.push('/');
            },
        },
    }
</script>
