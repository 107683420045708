<template>
    <popup class="icph-popup-profile" title="User Profile" subtitle="Please fill in your information." color="primary" v-model:show="show">
        <perfect-scrollbar
            tag="form"
            @submit.prevent="update_profile"
            :options="{ suppressScrollX: true }"
        >
            <base-input
                :round="true"
                :dark="true"
                label="NAME &amp; SURNAME"
                placeholder="Enter your name"
                v-model="model.name"
                :error="get_errors('name')"
                >
            </base-input>
            <base-input
                :round="true"
                :dark="true"
                type="email"
                label="E-MAIL ADDRESS"
                placeholder="Enter your e-mail address"
                v-model="model.email"
                :error="get_errors('email')"
                >
            </base-input>
            <base-input
                :round="true"
                :dark="true"
                label="ORGANISATION"
                v-model="model.organization"
                placeholder="Enter your organisation's name"
                >
            </base-input>
            <base-input
                :round="true"
                :dark="true"
                :disabled="true"
                label="ROLE"
                placeholder="Enter your nickname"
                v-model="model.role"
                >
            </base-input>
            <dropdown
                label="BASED COUNTRY"
                placeholder="Select your country"
                v-model="model.country_id"
                :options="countries"
                :dark="true"
                :round="true"
                >
            </dropdown>
            <dropdown
                label="GEOGRAPHIC COVERAGE"
                placeholder="Select your scope"
                v-model="model.countries"
                mode="multiple"
                :options="countries"
                :dark="true"
                :round="true"
                >
            </dropdown>
            <multi-select
                label="THEMATIC SCOPE"
                placeholder="Select your scope"
                v-model="model.thematic_scope"
                :options="topics"
                :dark="true"
                :round="true"
                >
            </multi-select>
            <upload-input
                :round="true"
                :dark="true"
                v-model="model.profile_picture"
                @file="profile_picture_changed"
                icon-right="icon-upload"
                accept="images"
                label="UPLOAD PROFILE PICTURE"
                placeholder="Choose a profile picture from your computer (2Mb. Max)"
                >
            </upload-input>
            <profile-picture
                :dark="true"
                :src="(profile_picture ? profile_picture : get_user_picture())"
                >
                <div class="icph-profile-picture-info">
                    <p>Your profile picture will appear in a circle on your profile, so it is important to maintain a 1:1 aspect ratio. The best file types are JPG or PNG.</p>
                    <button
                        class="icph-remove-picture"
                        v-if="profile_picture"
                        @click.prevent="remove_profile_picture"
                    >
                        Remove current profile picture x
                    </button>
                </div>
            </profile-picture>
            <base-input
                :round="true"
                :dark="true"
                :error="get_errors('password')"
                v-model="model.password"
                class="icph-password-input"
                type="password"
                label="NEW PASSWORD"
                placeholder="Please enter new password here"
                >
            </base-input>
            <base-input
                :round="true"
                :dark="true"
                :error="get_errors('password_confirmation')"
                v-model="model.password_confirmation"
                class="icph-password-input"
                type="password"
                label="CONFIRM NEW PASSWORD"
                placeholder="Please confirm new password here"
                >
            </base-input>
            <Button
                class="icph-save-button"
                color="secondary"
                :round="true"
                type="submit"
                >
                SAVE PROFILE
            </Button>
            <Button
                class="icph-logout"
                color="secondary"
                :round="true"
                @click="logout"
                >
                LOGOUT
            </Button>
        </perfect-scrollbar>
    </popup>
</template>

<script>
    import Popup from  '@/components/Popup';
    import BaseInput from '@/components/Inputs/BaseInput';
    import UploadInput from '@/components/Inputs/UploadInput';
    import Dropdown from '@/components/Inputs/Dropdown';
    import MultiSelect from '@/components/Inputs/MultiSelect';
    import Button from '@/components/Buttons/Button';
    import ProfilePicture from '@/components/ProfilePicture';

    import { CountryService, TopicService, UserService } from '@/services';

    import useVuelidate from '@vuelidate/core';
    import { helpers, required, email, minLength } from '@vuelidate/validators';

    export default
    {
        inheritAttrs: false,
        name: 'ProfilePopup',
        components:
        {
            Popup,
            ProfilePicture,
            BaseInput,
            UploadInput,
            Dropdown,
            MultiSelect,
            Button,
        },
        props:
        {
            modelValue: Boolean,
        },
        emits: ['update:modelValue', 'close'],
        setup()
        {
            return { v$: useVuelidate() };
        },
        data()
        {
            return {
                countries: [],
                topics: [],
                country: {},
                profile_picture: '',
                model:
                {
                    name: '',
                    email: '',
                    thematic_scope: '',
                    organization: '',
                    profile_picture: {},
                    countries: [],
                    country_id: 0,
                    region_id: 0,
                    password: '',
                    password_confirmation: '',
                },
            };
        },
        validations()
        {
            return {
                model:
                {
                    name: { required },
                    email: { required, email },
                    password: { minLength: minLength(6) },
                    password_confirmation: {
                        sameAsPass: helpers.withMessage('Confirm password should be the same as password', () => this.model.password === this.model.password_confirmation)
                    },
                },
            };
        },
        computed:
        {
            user()
            {
                return this.$store.getters['Auth/user'];
            },
            show:
            {
                get()
                {
                    return this.modelValue;
                },
                set(value)
                {
                    this.$emit('update:modelValue', value);
                },
            },
        },
        async mounted()
        {
            this.model = {...this.model, ...this.user};

            this.countries = (await CountryService.all()).data;
            this.topics = (await TopicService.all()).data;
            // this.country = this.countries.filter((value) => value.id === this.model.country_id).pop();
            // this.model.profile_picture = (this.user.profile_picture_id ? process.env.VUE_APP_BACKEND_URL + 'api/files/' + this.user.profile_picture_id : 0);

            this.model.country_id = String(this.model.country_id);

            this.countries.forEach((item) =>
            {
                item.value = item.id;
                item.label = item.name;
            });

            this.model.countries.forEach((item, index) =>
            {
                this.model.countries[index] = String(item);
            });

            this.topics.forEach((item) =>
            {
                item.value = item.name;
                item.label = item.name;
            });
        },
        methods:
        {
            profile_picture_changed()
            {
                this.profile_picture = URL.createObjectURL(this.model.profile_picture);
            },
            async update_profile()
            {
                const is_valid = await this.v$.$validate();

                if (!is_valid)
                {
                    return ;
                }

                if (this.country.id)
                {
                    this.model.country_id = this.country.id;
                }

                let form_data = new FormData();

                form_data.append('name', this.model.name);
                form_data.append('email', this.model.email);
                // form_data.append('thematic_scope', []);
                this.model.thematic_scope.forEach((item, key) =>
                {
                    console.log(item, key)
                    form_data.append('thematic_scope[' + key + ']', item);
                });
                form_data.append('organization', this.model.organization);
                form_data.append('profile_picture', this.model.profile_picture);
                form_data.append('country_id', this.model.country_id);
                form_data.append('password', this.model.password);
                form_data.append('password_confirmation', this.model.password_confirmation);
                // form_data.append('countries', this.model.countries);
                this.model.countries.forEach((item, key) =>
                {
                    form_data.append('countries[' + key + ']', item);
                });

                UserService.update(this.user.id, form_data).then(() =>
                {
                    this.$toast.success('User profile was successfully updated!');
                },
                (error) =>
                {
                    if (error.data.message)
                    {
                        this.$toast.error(error.data.message);
                    }
                    else
                    {
                        this.$toast.error('Something went wrong. Please try again!');
                    }
                });
            },
            remove_profile_picture()
            {
                this.model.profile_picture = {};
                this.profile_picture = undefined;
            },
            logout()
            {
                this.show = false;
                this.$router.push('/user/logout');
            },

            get_user_picture()
            {
                return (this.user.profile_picture_id ? process.env.VUE_APP_BACKEND_URL + 'api/files/' + this.user.profile_picture_id : 0)
            },

            get_errors(field, message)
            {
                const $err = this.v$.model[field];

                if ($err.$error)
                {
                    return message || $err.$errors.map((err) => err.$message).join(' - ');
                }

                return false;
            }
        }
    }
</script>
